import React, { useEffect } from "react";
import { Center, Stack, Text } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFinalizePaymentMutation } from "../api";

interface SuccessfulPaymentPageProps {}

export const SuccessfulPaymentPage: React.FC<SuccessfulPaymentPageProps> = (
  props: SuccessfulPaymentPageProps
) => {
  const navigate = useNavigate();
  const [finalizePayment, finalizePaymentResult] = useFinalizePaymentMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  const ticketId = searchParams.get("ticketId") as string;

  useEffect(() => {
    finalizePayment({
      data: {
        ticketId: ticketId.split("?")[0],
        transactionId: ticketId.split("?")[1].split("=")[1]
      }
    });
  }, []);

  useEffect(() => {
    if (finalizePaymentResult.isSuccess) {
      navigate(
        `/ticket/details?ticketId=${ticketId.split("?")[0]}&transactionId=${
          ticketId.split("?")[1].split("=")[1]
        }`
      );
    }
  }, [finalizePaymentResult]);

  return (
    <Stack>
      <Center height="90vh" width="100vw">
        <Stack width="512px" spacing="12">
          <Text textAlign={"center"} fontSize={"3xl"} color="green.600">
            Ticket Booked Successfully
          </Text>
          <Text textAlign={"center"} fontSize={"xl"} color="gray.600">
            Redirecting to ticket details page...
          </Text>
        </Stack>
      </Center>
    </Stack>
  );
};
