import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  PinInputField,
  Center,
  Heading,
  PinInput,
  HStack,
  Text,
  useToast
} from "@chakra-ui/react";
import { useTypedDispatch } from "hooks/use-typed-dispatch.hook";
import {
  savePatientList,
  sendOtpRequest,
  verifyPhoneNumber
} from "../state/patient.slice";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import {
  useLazySearchPatientQuery,
  useSendTotpMutation,
  useVerifyTotpMutation
} from "../api";
import { FaSearch } from "react-icons/fa";
import dayjs from "dayjs";

interface OtpModalComponentProps {
  phoneNumber: string;
}

export const OtpModalComponent: React.FC<OtpModalComponentProps> = (
  props: OtpModalComponentProps
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const patientState = useTypedSelector((state) => state.patient);
  const dispatch = useTypedDispatch();

  const [sendTotp, sendTotpResult] = useSendTotpMutation();
  const [verifyTotp, verifyTotpResult] = useVerifyTotpMutation();
  const [searchPatient, searchPatientResult] = useLazySearchPatientQuery();

  const phoneNumberRegex = /^01\d{9}/;
  const [otp, setOtp] = useState<string>("");

  const handleOtpChange = (value: string) => {
    setOtp(value);
  };

  const handleSendOtpRequest = () => {
    sendTotp({
      data: {
        contact: props.phoneNumber
      }
    });
  };

  useEffect(() => {
    if (sendTotpResult.isSuccess) {
      dispatch(
        sendOtpRequest({
          phoneNumber: props.phoneNumber
        })
      );

      // toast({
      //   title: "OTP Demo",
      //   description: sendTotpResult.data.message,
      //   status: "info",
      //   position: "top",
      //   duration: 5000,
      //   isClosable: true
      // });
    }
  }, [sendTotpResult]);

  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleVerifyOtpRequest = () => {
    verifyTotp({
      data: {
        contact: props.phoneNumber,
        otp: otp
      }
    });
  };

  useEffect(() => {
    if (verifyTotpResult.isSuccess) {
      dispatch(
        verifyPhoneNumber({
          otp
        })
      );
      searchPatient({
        code: otp,
        contactNumber: props.phoneNumber
      });

      toast({
        title: "Success",
        description: "Phone number verified successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });

      onClose();
    } else if (verifyTotpResult.isError) {
      toast({
        title: "Error",
        description: "Invalid OTP",
        status: "error",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [verifyTotpResult]);

  useEffect(() => {
    if (searchPatientResult.isSuccess) {
      dispatch(
        savePatientList({
          listOfPatientWithDetails:
            searchPatientResult.data.listOfPatientWithDetails
        })
      );
    }
  }, [searchPatientResult]);

  return (
    <>
      <Button
        width="50%"
        size={{ base: "md", lg: "lg" }}
        colorScheme={"blue"}
        onClick={onOpen}
        leftIcon={<FaSearch />}
        disabled={!phoneNumberRegex.test(props.phoneNumber)}
      >
        Search
      </Button>

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verify Phone Number</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <Stack spacing="4">
                {patientState.nextOtpSendingTime ? (
                  <Stack>
                    <Center>
                      <Heading fontSize="md">Submit your OTP</Heading>{" "}
                    </Center>
                    <Center>
                      <HStack>
                        <PinInput
                          otp
                          size="lg"
                          placeholder="-"
                          onChange={handleOtpChange}
                        >
                          <PinInputField autoFocus borderColor="blue.900" />
                          <PinInputField borderColor="blue.900" />
                          <PinInputField borderColor="blue.900" />
                          <PinInputField borderColor="blue.900" />
                        </PinInput>
                      </HStack>
                    </Center>
                    <Button
                      colorScheme="blue"
                      onClick={handleVerifyOtpRequest}
                      isLoading={verifyTotpResult.isLoading}
                    >
                      Verify
                    </Button>

                    {currentTime.isAfter(
                      dayjs(patientState.nextOtpSendingTime)
                    ) ? (
                      <Button
                        colorScheme="blue"
                        onClick={handleSendOtpRequest}
                        isLoading={sendTotpResult.isLoading}
                      >
                        Resend OTP to {props.phoneNumber}
                      </Button>
                    ) : (
                      <Text textAlign={"center"} color="gray.500">
                        Resend OTP in{" "}
                        {dayjs(patientState.nextOtpSendingTime).diff(
                          currentTime,
                          "seconds"
                        )}{" "}
                        seconds
                      </Text>
                    )}
                  </Stack>
                ) : (
                  <Stack>
                    <Text fontSize="lg">
                      We will verify your phone number by sending an OTP to your
                      phone number. Please click the button below to receive an
                      OTP.
                    </Text>

                    <Button
                      colorScheme="blue"
                      onClick={handleSendOtpRequest}
                      isLoading={sendTotpResult.isLoading}
                    >
                      Send OTP to {props.phoneNumber}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
