import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import duration from "dayjs/plugin/duration";

import {
  Text,
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image,
  Svg,
  Line
} from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import {
  PatientWithDetails,
  Person,
  TicketWithDetails,
  Location as LocationType
} from "app/api/type";
import { env } from "app/config";

interface PrintableOpdTicketProps {
  ticketWithDetails: TicketWithDetails;
  patientWithDetails: PatientWithDetails;
  hospitalLocation: LocationType;
  printingLocation: LocationType;
  printerPerson: Person;
}

Font.register({
  family: "Lora",
  fonts: [
    { src: "/fonts/Lora-Regular.ttf" },
    { src: "/fonts/Lora-Bold.ttf", fontWeight: 600 }
  ]
});

Font.register({
  family: "Kalpurush",
  src: "/fonts/Kalpurush.ttf"
});

const styles = StyleSheet.create({
  body: {
    fontFamily: "Lora",
    paddingTop: 20,
    paddingBottom: 24,
    paddingHorizontal: 24
  },
  logo: {
    width: "18%",
    objectFit: "contain",
    textAlign: "center"
  },
  textTitle: {
    fontSize: 14,
    marginTop: 3,
    marginBottom: 2,
    textAlign: "center",
    fontWeight: 600
  },
  title: {
    fontSize: 14,
    marginBottom: 14,
    textAlign: "center",
    fontWeight: 600
  },
  barcode: {
    width: "130px",
    height: "100px",
    left: "40",
    paddingVertical: "4px"
  },
  logoRx: {
    bottom: "0",
    top: "10",
    left: "170",
    width: "18px",
    objectFit: "contain"
  },
  table: {
    width: "100%"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 9
  },
  serialNo: {
    textAlign: "center",
    fontSize: 13,
    fontFamily: "Lora"
  },
  departmentName: {
    textAlign: "center",
    fontSize: 13,
    fontFamily: "Kalpurush"
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    fontSize: 10,
    paddingBottom: "1px",
    width: "100%"
  },
  ticketTitle: {
    textAlign: "left",
    width: "300px",
    padding: "2px",
    fontWeight: 600
  },
  ticketSecondTitle: {
    textAlign: "left",
    width: "55px",
    paddingVertical: "2px",
    fontWeight: 600
  },
  ticketValue: {
    textAlign: "left",
    padding: "2px",
    width: "250px"
  },
  ticketSecondValue: {
    textAlign: "left",
    padding: "2px",
    width: "140px",
    fontSize: 10
  },
  prescriptionValue: {
    textAlign: "left",
    padding: "2px",
    width: "200px"
  },
  ticketDesign: {
    textAlign: "center",
    padding: "2px",
    width: "1%"
  },
  ticketDate: {
    width: "100%",
    paddingBottom: "4px",
    textAlign: "center"
  },
  observationHeader: {
    fontSize: 14,
    marginTop: 2,
    marginBottom: 2,
    textAlign: "left",
    fontWeight: 600
  },
  signatureName: {
    bottom: "5px",
    width: "140px",
    paddingTop: "2px",
    left: "400"
  },
  signature: {
    bottom: "5px",
    left: "400",
    width: "140px",
    borderTop: "1px solid #000",
    paddingTop: "2px"
  },
  rowAlign: {
    display: "flex",
    flexDirection: "row"
  }
});
export const PrintableOpdTicketComponent: React.FC<PrintableOpdTicketProps> = (
  props: PrintableOpdTicketProps
) => {
  dayjs.extend(duration);

  const convertSlotToString = (slot: number) => {
    switch (slot) {
      case 0:
        return "08:00 AM to 10:00 AM";
      case 1:
        return "10:00 AM to 12:00 PM";
      case 2:
        return "12:00 PM to 02:00 PM";
      default:
        return "- to -";
    }
  };

  const [barcode, setBarcode] = useState<string>();

  useEffect(() => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, props.ticketWithDetails.ticket.id);
    setBarcode(canvas.toDataURL());
  }, []);

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <Image style={styles.logo} src="/images/bdgovtlogo.png" />
              <View style={{ width: "70%" }}>
                <View>
                  <Text
                    style={{
                      fontSize: 16,
                      alignSelf: "center",
                      fontFamily: "Kalpurush"
                    }}
                  >
                    {props.hospitalLocation.description}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 9,
                      alignSelf: "center",
                      fontWeight: 600
                    }}
                  >
                    {props.hospitalLocation.displayName}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 9,
                      alignSelf: "center"
                    }}
                  >
                    Address: {props.hospitalLocation.shortAddress}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      alignSelf: "center"
                    }}
                  >
                    Contact: {props.hospitalLocation.contactNumber} | Email:{" "}
                    {props.hospitalLocation.email}
                  </Text>
                </View>
              </View>
              <Image style={styles.logo} src={env.organizationLogo} />
            </View>
            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "4px",
                marginTop: "4px"
              }}
            >
              <View>
                <Text style={styles.title}>OPD TICKET </Text>
              </View>

              <View style={styles.table}>
                <View style={styles.row} wrap={false}>
                  <View style={styles.row}>
                    <View style={styles.content}>
                      <View style={styles.itemRow}>
                        <Text style={styles.ticketTitle}>Ticket ID</Text>
                        <Text style={styles.ticketDesign}>:</Text>
                        <Text style={styles.ticketValue}>
                          {props.ticketWithDetails.ticket.id}
                        </Text>
                      </View>
                      <View style={[styles.itemRow]}>
                        <Text style={styles.ticketTitle}>Patient ID</Text>
                        <Text style={styles.ticketDesign}>:</Text>
                        <Text style={styles.ticketValue}>
                          {props.ticketWithDetails.ticket.patientId}
                        </Text>
                      </View>
                      <View style={[styles.itemRow]}>
                        <Text style={styles.ticketTitle}>Gender</Text>
                        <Text style={styles.ticketDesign}>:</Text>
                        <Text style={styles.ticketValue}>
                          {props.patientWithDetails.person
                            .gender!.charAt(0)
                            .toUpperCase() +
                            props.patientWithDetails.person.gender!.slice(1)}
                        </Text>
                      </View>
                      <View style={[styles.itemRow]}>
                        <Text style={styles.ticketTitle}>Visit Date</Text>
                        <Text style={styles.ticketDesign}>:</Text>
                        <Text style={styles.ticketValue}>
                          {dayjs(
                            props.ticketWithDetails.ticket.bookedFor
                          ).format("DD MMM YYYY")}{" "}
                          from{" "}
                          {convertSlotToString(
                            props.ticketWithDetails.ticket.timeSlotSerial
                              ? props.ticketWithDetails.ticket.timeSlotSerial
                              : 0
                          )}
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.content, { paddingLeft: "15px" }]}>
                      <View style={[styles.itemRow]} wrap>
                        <Text style={styles.ticketSecondTitle}>Name</Text>
                        <Text style={styles.ticketDesign}>:</Text>
                        <Text style={styles.ticketSecondValue}>
                          {props.patientWithDetails.person.name!.toUpperCase()}
                        </Text>
                      </View>
                      <View style={[styles.itemRow]}>
                        <Text style={styles.ticketSecondTitle}>Age</Text>
                        <Text style={styles.ticketDesign}>:</Text>
                        <Text style={styles.ticketSecondValue}>
                          {dayjs
                            .duration(
                              dayjs().diff(
                                dayjs(
                                  props.patientWithDetails.person.dateOfBirth
                                ),
                                "years",
                                true
                              ),
                              "years"
                            )
                            .format("YY[Y] MM[M] DD[D]")}
                        </Text>
                      </View>
                      <View style={[styles.itemRow]}>
                        <Text style={styles.ticketSecondTitle}>Contact</Text>
                        <Text style={styles.ticketDesign}>:</Text>
                        <Text style={styles.ticketSecondValue}>
                          {props.patientWithDetails.person.contactNumber}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={styles.content}>
                    <View style={[styles.itemRow]}>
                      <Text
                        style={{
                          textAlign: "left",
                          width: "80px",
                          marginLeft: "40px",
                          padding: "2px",
                          fontWeight: 600
                        }}
                      >
                        Health ID
                      </Text>
                      <Text style={styles.ticketDesign}>:</Text>
                      <Text style={styles.ticketSecondValue}></Text>
                    </View>

                    <Image style={styles.barcode} src={barcode} />
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >
              <Text style={[styles.serialNo, { fontWeight: "bold" }]}>
                Department:
              </Text>
              <Text style={[styles.departmentName]}>
                {props.ticketWithDetails.assignedLocation.displayName + "   "}
              </Text>
            </View>

            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            ></View>
          </View>

          <View style={{ marginBottom: "1px" }}>
            <Image style={styles.logoRx} src="/images/rx.png" />
            <Svg height="700" width="1200">
              <Line
                x1="160"
                y1="0"
                x2="160"
                y2="700"
                strokeWidth={1}
                stroke="#000"
              />
            </Svg>

            <View style={styles.signature}>
              <Text style={{ fontSize: 8, textAlign: "center" }}>
                Doctor's Signature
              </Text>
            </View>
          </View>

          <View>
            <View style={styles.ticketDate}>
              <Text style={{ fontSize: 8, color: "#666666" }}>
                Note: This ticket is usable until{" "}
                {dayjs(props.ticketWithDetails.ticket.bookedFor)
                  .add(1, "day")
                  .day() === 5
                  ? dayjs(props.ticketWithDetails.ticket.bookedFor)
                      .add(2, "day")
                      .format("DD MMM YYYY")
                  : dayjs(props.ticketWithDetails.ticket.bookedFor)
                      .add(1, "day")
                      .format("DD MMM YYYY")}{" "}
                from{" "}
                {convertSlotToString(
                  props.ticketWithDetails.ticket.timeSlotSerial
                    ? props.ticketWithDetails.ticket.timeSlotSerial
                    : 0
                )}
              </Text>
            </View>

            <View
              style={{
                borderTop: "1px solid #000"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: "2px"
                }}
              >
                <Text style={{ fontSize: 6, color: "#666666" }}>
                  Powered By: BinduLogic Limited
                </Text>
                <Text style={{ fontSize: 6, color: "#666666" }}>
                  Printed By: {props.printerPerson.name}, at{" "}
                  {props.printingLocation.name},{" "}
                  {dayjs().format("hh:mm:ss A, (DD MMM YYYY)")}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
