import { Center, Image, Link, Stack, Text } from "@chakra-ui/react";
import React from "react";

interface SupportingOrganizationComponentProps {}

export const SupportingOrganizationComponent: React.FC<
  SupportingOrganizationComponentProps
> = (props: SupportingOrganizationComponentProps) => {
  return (
    <Center width="100%" textAlign="center" pt="16" pb={4}>
      <Stack
        direction="row"
        width="100%"
        justify={"space-around"}
        alignItems="center"
      >
        <Image
          src="/images/dghs-logo.png"
          height={{ base: 12, lg: 20 }}
          objectFit="contain"
        />
        <Image
          src="/images/binduhealth-logo.png"
          height={{ base: 8, lg: 12 }}
          objectFit="contain"
        />
        <Image
          src="/images/mis-logo.png"
          height={{ base: 12, lg: 20 }}
          objectFit="contain"
        />
      </Stack>
    </Center>
  );
};
