import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  FormControl,
  Input,
  FormLabel,
  useToast,
  IconButton,
  Tooltip,
  Text,
  HStack,
  Spacer
} from "@chakra-ui/react";
import { Location as LocationType, PatientWithDetails } from "app/api/type";
import dayjs from "dayjs";
import {
  useBookTicketMutation,
  useGetAllDepartmentsQuery,
  useStartPaymentMutation
} from "../api";
import { Select } from "chakra-react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { FaInfoCircle, FaTicketAlt } from "react-icons/fa";
import { env } from "app/config";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

interface BookTicketModalComponentProps {
  patientWithDetails: PatientWithDetails;
}

export const BookTicketModalComponent: React.FC<
  BookTicketModalComponentProps
> = (props: BookTicketModalComponentProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [startPayment, startPaymentResult] = useStartPaymentMutation();

  const patientState = useTypedSelector((state) => state.patient);
  const getAllDepartmentsResult = useGetAllDepartmentsQuery({});
  const [bookTicket, bookTicketResult] = useBookTicketMutation();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<{
    label: string;
    value: number;
  }>();

  const timeSlots = [
    {
      label: "08:00 AM - 10:00 AM",
      value: 0
    },
    {
      label: "10:00 AM - 12:00 PM",
      value: 1
    },
    {
      label: "12:00 PM - 02:00 PM",
      value: 2
    }
  ];

  const [departmentList, setDepartmentList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const [selectedDepartment, setSelectedDepartment] = useState<{
    value: string;
    label: string;
  }>();

  const [bookingDate, setBookingDate] = useState<Date>(
    dayjs().tz("Asia/Dhaka").hour() >= 12
      ? dayjs().tz("Asia/Dhaka").add(1, "day").day() === 5
        ? dayjs().tz("Asia/Dhaka").add(2, "day").toDate()
        : dayjs().tz("Asia/Dhaka").add(1, "day").toDate()
      : dayjs().tz("Asia/Dhaka").toDate()
  );

  const filterDate = (date: Date) => {
    const day = date.getDay();
    return day !== 5;
  };

  useEffect(() => {
    if (getAllDepartmentsResult.data) {
      setDepartmentList(
        getAllDepartmentsResult.data.locations.map((location) => {
          return {
            label: location.displayName,
            value: location.id
          };
        })
      );
    }
  }, [getAllDepartmentsResult]);

  const handleBookTicket = () => {
    bookTicket({
      data: {
        otp: patientState.otp!,
        contactNumber: patientState.phoneNumber!,
        ticket: {
          patientId: props.patientWithDetails.patient.id,
          sourceLocationId: env.onlineLocationId,
          assignedLocationId: selectedDepartment
            ? selectedDepartment.value
            : "",
          ticketCategoryName: "Online",
          bookedFor: bookingDate,
          timeSlotDate: bookingDate,
          timeSlotLocationId: selectedDepartment
            ? selectedDepartment.value
            : "",
          timeSlotSerial: selectedTimeSlot ? selectedTimeSlot.value : 0,
          userId: null
        }
      }
    });
  };

  useEffect(() => {
    if (bookTicketResult.isSuccess) {
      startPayment({
        data: {
          ticketId: bookTicketResult.data.ticketWithDetails.ticket.id
        }
      });
    }
  }, [bookTicketResult]);

  useEffect(() => {
    if (startPaymentResult.isSuccess) {
      window.location.href = startPaymentResult.data.url;
    }
  }, [startPaymentResult]);

  return (
    <>
      <Button colorScheme={"blue"} onClick={onOpen} leftIcon={<FaTicketAlt />}>
        Book Ticket
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "lg", lg: "5xl" }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Book Ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <FormControl isReadOnly>
                <FormLabel>Patient ID</FormLabel>
                <Input value={props.patientWithDetails.patient.id} />
              </FormControl>

              <FormControl isReadOnly>
                <FormLabel>Patient Name</FormLabel>
                <Input
                  value={
                    props.patientWithDetails.person.name
                      ? props.patientWithDetails.person.name
                      : "Unknown"
                  }
                />
              </FormControl>

              <FormControl id="department" isRequired>
                <FormLabel>Department</FormLabel>
                <Select
                  menuPlacement="top"
                  selectedOptionStyle="check"
                  options={departmentList}
                  value={selectedDepartment}
                  onChange={(event) => {
                    if (event) {
                      setSelectedDepartment({
                        label: event.label,
                        value: event.value
                      });
                    }
                  }}
                />
              </FormControl>

              <FormControl id="timeSlot" isRequired>
                <FormLabel>Time Slot</FormLabel>
                <Select
                  menuPlacement="top"
                  selectedOptionStyle="check"
                  options={timeSlots}
                  value={selectedTimeSlot}
                  onChange={(event) => {
                    if (event) {
                      setSelectedTimeSlot({
                        label: event.label,
                        value: event.value
                      });
                    }
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Booking Date</FormLabel>
                <DatePicker
                  selected={bookingDate}
                  filterDate={filterDate}
                  onChange={(date) => date && setBookingDate(date)}
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // minDate should be tomorrow if 12 PM has passed in GMT+6
                  minDate={
                    dayjs().tz("Asia/Dhaka").hour() >= 12
                      ? dayjs().tz("Asia/Dhaka").add(1, "day").day() === 5
                        ? dayjs().tz("Asia/Dhaka").add(2, "day").toDate()
                        : dayjs().tz("Asia/Dhaka").add(1, "day").toDate()
                      : dayjs().tz("Asia/Dhaka").toDate()
                  }
                  // minDate={
                  //   dayjs().tz("Asia/Dhaka").hour() >= 12 &&
                  //   dayjs().tz("Asia/Dhaka").day() === 4
                  //     ? dayjs().tz("Asia/Dhaka").add(2, "day").toDate() // Skip Friday
                  //     : dayjs().tz("Asia/Dhaka").add(1, "day").toDate()
                  // }
                  maxDate={dayjs().tz("Asia/Dhaka").add(7, "days").toDate()}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4} justifyContent="space-between" w="100%">
              <Tooltip
                label={
                  <>
                    <Text>Ticket Price : ৳10 </Text>
                    <Text>Conveyance Charge : ৳0.5 </Text>
                  </>
                }
                placement="top"
              >
                <IconButton
                  aria-label="View Price"
                  icon={<FaInfoCircle color="blue" />}
                  variant="transparent"
                />
              </Tooltip>
              <Spacer />
              <Button colorScheme="red" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isDisabled={!bookingDate || !selectedDepartment}
                colorScheme="blue"
                onClick={handleBookTicket}
                isLoading={
                  bookTicketResult.isLoading || startPaymentResult.isLoading
                }
              >
                Book Ticket
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
