import { Center, Image, Link, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { ContactAddressComponent } from "./contact-address.component";

interface BindulogicCreditsComponentProps {}

export const BindulogicCreditsComponent: React.FC<
  BindulogicCreditsComponentProps
> = (props: BindulogicCreditsComponentProps) => {
  return (
    <Center width="100%" textAlign="center" pt={{ base: 4, lg: 8 }} pb={4}>
      <Stack direction="column" width="100%">
        <Center>
          <Stack direction={{ base: "column", lg: "row" }} alignItems="center">
            <Text color="gray.500" fontSize={{ base: "sm" }}>
              ©{" "}
              <Text as="span" fontWeight={"bold"}>
                binduhealth
              </Text>
              , a product of
            </Text>
            <Center>
              <Stack direction="row">
                <Image
                  src="/images/bindulogic-logo.png"
                  width={5}
                  objectFit="cover"
                />
                <Link
                  color="blue.400"
                  href="https://bindulogic.com/"
                  isExternal
                  fontSize={{ base: "sm" }}
                >
                  <Text as="span" fontWeight={"bold"}>
                    bindulogic limited{" "}
                  </Text>{" "}
                </Link>{" "}
              </Stack>{" "}
            </Center>
          </Stack>{" "}
        </Center>

        <ContactAddressComponent />
      </Stack>
    </Center>
  );
};
